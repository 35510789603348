import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Container, Box, Input } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import theme from './styles/home/_theme'
import styles from './styles/home/_styles'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import ContentText from '@solid-ui-components/ContentText/ContentText'

const ForgotPasswordPage = props => {
  const [values, setValues] = useState({})
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState({
    status: false,
    msg: ''
  })
  let params

  if (typeof window !== 'undefined') {
    params = new URL(document.location).searchParams
  }

  const token = params?.get('token')
  const API_ENDPOINT = `https://api.tymeline.app/api/v1/individual/reset-password?token=${token}`

  const handlePasswordChange = e => {
    setValues(prevValues => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }))
  }

  const handleResetPasswordSubmit = () => {
    if (!!values.password && !!values.confirmPassword) {
      if (values.password === values.confirmPassword) {
        postResetPasswordData()
      } else {
        handleError('Passwords didnt match')
      }
    } else {
      handleError('Please enter the password and proceed')
    }
  }

  const handleError = msg => {
    setShowError({
      status: true,
      msg
    })

    setTimeout(() => {
      setShowError({
        status: false,
        msg: ''
      })
    }, 3000)
  }

  const postResetPasswordData = () => {
    fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password: values.password })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data)
        setShowSuccess(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Tymeline ID' />
      <Container variant='wide' sx={styles.passwordContainer}>
        <Container sx={styles.passwordSectionContainer}>
          <div style={{ textAlign: 'center' }}>
            <ContentText
              content={[
                {
                  text: 'Reset Password',
                  color: '#f97316',
                  variant: 'h4'
                }
              ]}
            />
          </div>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center'
            }}
          >
            {showSuccess ? (
              <p style={{ color: 'green' }}>
                You have successfully changed the password. Please login again
                on your Tymeline App with new password
              </p>
            ) : (
              <Box>
                <Input
                  type='password'
                  name='password'
                  onChange={handlePasswordChange}
                  value={values.password}
                  sx={styles.passwordInput}
                  placeholder='New Password'
                />
                <Divider space='3' />
                <Input
                  type='password'
                  sx={styles.passwordInput}
                  name='confirmPassword'
                  onChange={handlePasswordChange}
                  value={values.confirmPassword}
                  placeholder='Confirm New Password'
                />
                <Divider space='3' />
                {showError.status && (
                  <div style={styles.errorText}>
                    {showError.msg}
                    <Divider space='1' />
                  </div>
                )}
                {/* <button>Reset Password</button> */}
                <div onClick={handleResetPasswordSubmit}>
                  <ContentButtons
                    content={[
                      {
                        text: 'Reset Password',
                        type: 'SUBMIT',
                        variant: 'secondary',
                        width: '200px'
                      }
                    ]}
                  />
                </div>
              </Box>
            )}
          </Box>
        </Container>
      </Container>
      {/* Blocks */}
    </Layout>
  )
}

export const query = graphql`
  query ForgotPasswordPage {
    allBlockContent(filter: { page: { in: ["homepage/app", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ForgotPasswordPage
