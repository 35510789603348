/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  featuresContainer: {
    position: `relative`,
    py: [5, 6],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: -3,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
    }
  },
  tabsContainer: {
    position: `relative`,
    py: [4, 5],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: 0,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
    }
  },
  errorText: {
    color: 'red'
  },
  passwordContainer: {
    position: `relative`,
    py: [5, 6],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: -3,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `#fff`
    }
  },
  sectionContainer: {
    padding: '0 3rem'
  },
  passwordSectionContainer: {
    background: '#fff',
    boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
    padding: '3rem 0',
    width: '70%',
    borderRadius: '2rem',
    '@media (max-width: 768px) ': {
      width: '100%'
    }
  },
  passwordInput: {
    width: '40%',
    margin: '0 auto',
    '@media (max-width: 768px) ': {
      width: '90%'
    }
  },
  testimonialsContainer: {
    position: `relative`,
    pt: 6,
    pb: 5,
    '::before': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `full`,
      top: 0,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
    }
  }
}
